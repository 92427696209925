<template>
	<div>
		<v-dialog v-model="dialog" class="modal-choice-cat">
			<v-card>
				<v-row class="modal-choice-cat-row mr-0 ml-0">
					<v-col class="pt-10 pb-10 d-flex flex-column text-center">
						<div class="pr-10 pl-10">
							<h1 class="mb-3">J-{{ decompteDays }}</h1>
							<p class="font-weight-black mb-6">Pour continuer choisis ton prochain programme</p>
						</div>
						<div>
							<v-btn
								class="hvr-grow actionButton mb-2"
								dark
								:to="{
									name: 'Chapter Report',
									params: { formationId: formationId, categoryId: lastCategoryIdDone },
									query: { step: 2 },
								}"
							>
								Choisir mon programme
							</v-btn>
							<p class="mb-0 ortho-text-gray backToDashboard" @click="dialog = false">Accéder à mon espace perso</p>
						</div>
					</v-col>
					<v-col class="col-nb-cats-done d-flex flex-column justify-center align-center text-center pa-6">
						<h3>Programmes finis</h3>
						<h1>{{ numCategoriesDoneOverTotalCategory }}</h1>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import moment from 'moment';
import { CategoryStatus } from '@/constants/category.js';

export default {
	name: 'AlertChoiceCategorySession',
	props: { formationProgress: Object },
	data() {
		return { dialog: true, lastCategoryIdDone: null, categoriesDone: null };
	},
	computed: {
		formationId() {
			if (this.formationProgress == null) return '';
			return this.formationProgress.formation._id;
		},
		decompteDays() {
			if (this.formationProgress == null) return '';

			const today = moment().startOf('day');
			const inThreeDays = moment(this.formationProgress.formation.last_exercice_date).startOf('day').add(3, 'days');
			return inThreeDays.diff(today, 'day');
		},
		numCategoriesDoneOverTotalCategory() {
			if (this.formationProgress == null || this.categoriesDone == null || this.categoriesDone.length === 0) return '';
			const currentParcoursItem = this.formationProgress.config.parcours_list.find(
				(item) => item.parcours_id._id === this.formationProgress.formation.parcours_id
			);
			const currentParcours = currentParcoursItem.parcours_id;

			const numCompleteCategory = currentParcours.categories.filter((cat) => cat.status === CategoryStatus.COMPLETED)
				.length;
			const totalCategory = currentParcours.categories.length;
			return `${numCompleteCategory}/${totalCategory}`;
		},
	},
	async mounted() {
		const categoriesDoneArr = await this.$store.dispatch('profile/fetchCategoriesDoneByFormationId', {
			formationId: this.formationProgress.formation._id,
		});
		this.categoriesDone = categoriesDoneArr;
		this.findLastCategoryIdDone(categoriesDoneArr);
	},
	methods: {
		findLastCategoryIdDone(categoriesDoneArr) {
			if (categoriesDoneArr.length === 0) return;
			const lastCategoryDone = [...categoriesDoneArr].sort((a, b) => new Date(b.done_date) - new Date(a.done_date))[0];
			this.lastCategoryIdDone = lastCategoryDone.category_id;
		},
	},
};
</script>

<style lang="scss" scoped>
.backToDashboard {
	cursor: pointer;
}
h1 {
	font-size: 50px;
}
.col-nb-cats-done {
	background: #8685e5 !important;
	color: white;
}
.v-card__text,
.v-card__title {
	word-break: normal;
}
::v-deep {
	.v-dialog {
		width: 50%;
		overflow-y: hidden !important;
	}

	@media screen and (max-width: 375px) {
		.actionButton {
			font-size: 11px;
		}
	}
	@media screen and (max-width: 600px) {
		.v-dialog {
			width: 90%;
		}
		.col-nb-cats-done {
			display: none !important;
		}
	}
}
</style>
